import React from "react";

import PageWrapper from "../components/PageWrapper";

const Privacy = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-center",
          headerButton: (
            <form>
              <button
                formaction="mailto:support@appshand.com"
                className="btn btn btn-dodger-blue-2 header-btn rounded-5"
              >
                Contact Us
              </button>
            </form>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Privacy Policy</h2>
                  <p className="font-size-7 mb-0">
                    If you choose to use our Service, then you agree to the
                    collection and use of information in relation to this
                    policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <p className="font-size-5 heading-default-color mb-0">
                      AppsHand built the Mobile App app as a Freemium app. This
                      SERVICE is provided by AppsHand at no cost and is intended
                      for use as is. This page is used to inform visitors
                      regarding our policies with the collection, use, and
                      disclosure of Personal Information if anyone decided to
                      use our Service. If you choose to use our Service, then
                      you agree to the collection and use of information in
                      relation to this policy. The Personal Information that we
                      collect is used for providing and improving the Service.
                      We will not use or share your information with anyone
                      except as described in this Privacy Policy. The terms used
                      in this Privacy Policy have the same meanings as in our
                      Terms and Conditions, which are accessible at Mobile App
                      unless otherwise defined in this Privacy Policy.
                    </p>
                    <div className="mt-9 mt-lg-11">
                      <h5 className="font-size-7 mb-7">
                        Information Collection
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        For a better experience, while using our Service, we may
                        require you to provide us with certain personally
                        identifiable information. The information that we
                        request will be retained by us and used as described in
                        this privacy policy. The app does use third-party
                        services that may collect information used to identify
                        you. Link to the privacy policy of third-party service
                        providers used by the app:
                        <ul>
                          <li>
                            <a
                              href="https://support.google.com/admob/answer/6128543?hl=en"
                              target="_blank"
                            >
                              Admob
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://firebase.google.com/policies/analytics"
                              target="_blank"
                            >
                              Google Analytics for Firebase
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://cloud.google.com/terms/cloud-privacy-notice"
                              target="_blank"
                            >
                              Google Cloud
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://firebase.google.com/support/privacy/"
                              target="_blank"
                            >
                              Firebase Crashlytics
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.facebook.com/about/privacy/update/printable"
                              target="_blank"
                            >
                              Facebook
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://onesignal.com/privacy_policy"
                              target="_blank"
                            >
                              OneSignal
                            </a>
                          </li>
                          <li>
                            <a href="https://expo.io/privacy" target="_blank">
                              Expo
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.revenuecat.com/privacy"
                              target="_blank"
                            >
                              RevenueCat
                            </a>
                          </li>
                        </ul>
                        <p className="font-size-5 heading-default-color">
                          When you use the app, we may collect information about
                          you, including:
                        </p>
                        <ul>
                          <li>
                            <u>Photographs you provide when you use the app.</u>{" "}
                            Via your camera or camera roll (if you have granted
                            us permission to access your camera or camera roll),
                            the in-app internet search functionality. We obtain
                            only the specific images you chose to modify using
                            the app. We do not collect your photo albums even if
                            you grant us your access to them.
                          </li>
                          <li>
                            <u>Face data.</u> When you provide the photographs
                            while using the app if applicable we may use Face
                            Detection SDKs for landmarks detection.
                          </li>
                          <li>
                            <u>App usage information.</u> Such as information
                            about how you use the App and interact with us,
                            including your preferred language, the date and time
                            when you first installed the App and the date and
                            time you last used the App.
                          </li>
                          <li>
                            <u>Device data.</u> Such as your computer and mobile
                            device operating system type and version number,
                            manufacturer and model, device ID, push tokens,
                            Google Advertising ID, Apple ID for Advertising,
                            browser type, screen resolution, IP address (and the
                            associated country in which you are located), the
                            website you visited before visiting our Site; and
                            other information about the device you are using to
                            visit the App.
                          </li>
                          <li>
                            <u>Online activity data.</u> Such as information
                            about your use of and actions on the App and the
                            Sites, including pages or screens you viewed, how
                            long you spent on a page or screen, navigation paths
                            between pages or screens, information about your
                            activity on a page or screen, access times, and
                            length of access. Our service providers and certain
                            third parties (e.g., online advertising networks and
                            their clients) also may collect this type of
                            information over time and across third-party
                            websites and mobile applications. This information
                            may be collected on our site using cookies, browser
                            web storage (also known as locally stored objects,
                            or “LSOs”) and similar technologies. We may collect
                            this information directly or through our use of
                            third-party software development kits (“SDKs”). SDKs
                            may enable third parties to collect information
                            directly from our App.
                          </li>
                          <li>
                            <u>Purchase history.</u> If you choose to purchase
                            an App subscription, such as confirmation that you
                            are a paid subscriber to the App.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-11">
                      <h5 className="font-size-7 mb-7">Information Use</h5>
                      <p className="font-size-5 heading-default-color">
                        AppsHand strongly believes that you have the right to
                        control the use of your personal information, and that
                        your privacy must be respected. We strictly limit the
                        collection and processing of your personal data, and to
                        the best of our abilities we will work only with other
                        organisations who do the same. We will not use personal
                        data that you provide to us in a manner inconsistent
                        with the purposes for which you provided it to us.
                      </p>
                      <ul className="heading-default-color">
                        <li>
                          <u>For compliance, fraud prevention, and safety.</u>{" "}
                          We may use your personal information and disclose it
                          to law enforcement, government authorities, and
                          private parties as we believe necessary or Appropriate
                          to: (a) protect our, your or others’ rights, privacy,
                          safety or property (including by making and defending
                          legal claims); (b) enforce the terms and conditions
                          that govern the Service; and (c) protect, investigate
                          and deter against fraudulent, harmful, unauthorized,
                          unethical or illegal activity.
                        </li>
                        <li>
                          <u>To operate and improve the App.</u> Enable you to
                          use the App’s features, provide technical support and
                          maintenance for the app and perform statistical
                          analysis about use of the app.
                        </li>
                        <li>
                          <u>To display advertisements to you.</u> If you use
                          the free version of the app, we work with advertising
                          partners to display advertisements within the App.
                          These advertisements are delivered by our advertising
                          partners and may be targeted based on your use of the
                          app or your activity elsewhere online.
                        </li>
                        <li>
                          <u>
                            To create anonymous, aggregated or de-identified
                            data.
                          </u>{" "}
                          We may create anonymous, aggregated or de-identified
                          data from your personal information and other
                          individuals whose personal information we collect. We
                          make personal information into anonymous, aggregated
                          or de-identified data by removing information that
                          makes the data personally identifiable to you. We may
                          use this anonymous, aggregated or de-identified data
                          and share it with third parties for our lawful
                          business purposes.
                        </li>
                        <li>
                          <u>With your consent.</u> In some cases, we may
                          specifically ask for your consent to collect, use or
                          share your personal information, such as when required
                          by law.
                        </li>
                      </ul>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Information Sharing</h5>
                      <p className="font-size-5 heading-default-color mb-9">
                        We do not rent or sell your personal data, including photos and videos, to any third parties outside the company or its affiliates.
                      </p>
                      <p className="font-size-5 heading-default-color mb-9">
                        We may share your personal data with businesses that are legally part of the same group of companies that we are part of, including our subsidiaries (“affiliates”). The affiliates act as our data processors and may perform data processing on our behalf (e.g., providing technical support or conducting analytics). Such Affiliates are bound by appropriate contractual safeguards in place.
                      </p>
                      <p className="font-size-5 heading-default-color mb-9">
                        We also may share your personal data, and other collected information with third-party organizations such as contractors and service providers that we use to support our business and who are bound by confidentiality and data protection terms (consistent with this Privacy Policy) to keep your personal data confidential and use it only based on our instructions (“Service Providers”).
                      </p>
                      <p className="font-size-5 heading-default-color mb-9">
                        Such Service Providers include:
                        <ul>
                          <li>
                            Cloud providers Google Cloud Platform and Amazon Web Services, which we use when you choose to upload your photos or videos to AppsHand's app.
                          </li>
                          <li>
                            Third-party analytics, as specified in this Privacy Policy.
                          </li>
                          <li>
                            Email delivery services
                          </li>
                        </ul>
                      </p>
                      <p className="font-size-5 heading-default-color mb-9">
                        For the avoidance of doubt, we do not share or transfer your photos, videos and other data to third parties (except temporarily storing your photos, videos and face data to our cloud providers Google Cloud Platform and Amazon Web Services to provide online processing function, and thereafter deleting such photos, videos and other data as described in this Privacy Policy). We use only secure places of storage, such as Google Cloud Platform and Amazon Web Services. Google LLC and Amazon Web Services, Inc. are located in the United States of America.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">How we process your Photos, Videos and Face Data</h5>
                      <p className="font-size-5 heading-default-color mb-9">
                        We care about your privacy. With this in mind, most processing activities occur on your device only. There are, however, there are exceptions to it:
                      </p>
                      <p className="font-size-5 heading-default-color mb-9">
                        When using the app's functionality we may use third party SDKs or APIs solutions. The only information which may be collectible while using previously mentioned solutions is face landmarks detection. This is required to provide you with the functionalities (checking the provided photo for presence of a person on it and face landmarks detection) of the app when applicable.
                        Third-party service providers used by the app:
                        <ul>
                          <li>
                            <a
                              href="https://developers.google.com/ml-kit/terms"
                              target="_blank"
                            >
                              Google (ML KIT)
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://firebase.google.com/support/privacy"
                              target="_blank"
                            >
                              Google (Firebase)
                            </a>
                          </li>
                          <li>
                            <a
                              href=" https://developers.google.com/terms"
                              target="_blank"
                            >
                              Google (Reverse Image Search)
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://cloud.google.com/terms/cloud-privacy-notice"
                              target="_blank"
                            >
                              Google (Google Cloud Platform)
                            </a>
                          </li>
                        </ul>
                      </p>
                      <p className="font-size-5 heading-default-color mb-9">
                        We store your original photo, video or face data on our servers for no longer than 24 hours. Within 24 hours your data is deleted. All data handling is secure and being executed by GCP (Google Cloud Platform) thus preventing any unauthorized access, use, alteration and disclosure.
                      </p>
                      <p className='font-size-5 heading-default-color mb-9'>
                        <b>Notwithstanding anything to the contrary elsewhere in this Privacy Policy:</b>
                        <ul>
                          <li>
                            We do not use the photos, videos and face data you provide when you use the app for any reason other than to provide you with the functionalities of the app.
                          </li>
                          <li>
                            We do not use photos, videos or face data to identify any individual user.
                          </li>
                          <li>
                            We do not use your photos, videos or face data for authentication, advertising, or marketing purposes, or to otherwise target a user in a similar manner.
                          </li>
                          <li>
                            We do not use your photos, videos or face data to build a user profile, or otherwise attempt, facilitate, or encourage third parties to identify anonymous users or reconstruct user profiles based on your photos, videos or face data.
                          </li>
                          <li>
                            We do not transfer, share, sell, or otherwise provide your photos, videos or face data to advertising platforms, analytics providers, data brokers, information resellers or other such parties.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Log Data</h5>
                      <p className="font-size-5 heading-default-color mb-9">
                        We want to inform you that whenever you use our Service,
                        in a case of an error in the app we collect data and
                        information (through third-party products) on your phone
                        called Log Data. This Log Data may include information
                        such as your device Internet Protocol (“IP”) address,
                        device name, operating system version, the configuration
                        of the app when utilizing our Service, the time and date
                        of your use of the Service, and other statistics.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Cookies</h5>
                      <p className="font-size-5 heading-default-color">
                        Cookies are files with a small amount of data that are
                        commonly used as anonymous unique identifiers. These are
                        sent to your browser from the websites that you visit
                        and are stored on your device's internal memory. This
                        Service does not use these “cookies” explicitly.
                        However, the app may use third-party code and libraries
                        that use “cookies” to collect information and improve
                        their services. You have the option to either accept or
                        refuse these cookies and know when a cookie is being
                        sent to your device. If you choose to refuse our
                        cookies, you may not be able to use some portions of
                        this Service.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Service Providers</h5>
                      <p className="font-size-5 heading-default-color">
                        We may employ third-party companies and individuals due
                        to the following reasons:
                        <ul>
                          <li>To facilitate our Service;</li>
                          <li>To provide the Service on our behalf;</li>
                          <li>To perform Service-related services;</li>
                          <li>
                            To assist us in analyzing how our Service is used.
                          </li>
                        </ul>
                        We want to inform users of this Service that these third
                        parties have access to their Personal Information. The
                        reason is to perform the tasks assigned to them on our
                        behalf. However, they are obligated not to disclose or
                        use the information for any other purpose.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Security</h5>
                      <p className="font-size-5 heading-default-color">
                        We value your trust in providing us your Personal
                        Information, thus we are striving to use commercially
                        acceptable means of protecting it. But remember that no
                        method of transmission over the internet, or method of
                        electronic storage is 100% secure and reliable, and we
                        cannot guarantee its absolute security.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Links to Other Sites</h5>
                      <p className="font-size-5 heading-default-color">
                        This Service may contain links to other sites. If you
                        click on a third-party link, you will be directed to
                        that site. Note that these external sites are not
                        operated by us. Therefore, we strongly advise you to
                        review the Privacy Policy of these websites. We have no
                        control over and assume no responsibility for the
                        content, privacy policies, or practices of any
                        third-party sites or services.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Children’s Privacy</h5>
                      <p className="font-size-5 heading-default-color">
                        These Services do not address anyone under the age of
                        13. We do not knowingly collect personally identifiable
                        information from children under 13 years of age. In the
                        case we discover that a child under 13 has provided us
                        with personal information, we immediately delete this
                        from our servers. If you are a parent or guardian and
                        you are aware that your child has provided us with
                        personal information, please contact us so that we will
                        be able to do the necessary actions.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">
                        Changes to This Privacy Policy
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        We may update our Privacy Policy from time to time.
                        Thus, you are advised to review this page periodically
                        for any changes. We will notify you of any changes by
                        posting the new Privacy Policy on this page. This policy
                        is effective as of 2022-10-13
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Contact Us</h5>
                      <p className="font-size-5 heading-default-color">
                        If you have any questions or suggestions about our
                        Privacy Policy, do not hesitate to contact us at
                        support@appshand.com.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Privacy;
